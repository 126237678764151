import Service, { inject as service } from '@ember/service';
import { isEmpty, isPresent, isNone } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { camelToSnake } from '@latermedia/ember-later-analytics/utils';
import { dropTask } from 'ember-concurrency';
import { isEqual } from 'lodash';
import { TrackedArray, TrackedObject } from 'tracked-built-ins';

import { ADDRESS_FORM_CONFIG, assembleAddress } from 'later/utils/address';
import {
  ADDRESS_FIELD_KEYS,
  ADDRESS_COUNTRIES_REQUIRING_POSTAL_CODE,
  ADDRESS_COUNTRIES_REQUIRING_STATE,
  ADDRESS_COUNTRIES_TAXABLE,
  ADDRESS_COUNTRIES_TAXABLE_EU
} from 'later/utils/constants';
import { fetch } from 'later/utils/fetch';
import { objectMap } from 'later/utils/object-methods';
import handleSettingsError from 'settings/utils/handle-settings-error';

import type IntlService from 'ember-intl/services/intl';
import type AccountModel from 'later/models/account';
import type AuthService from 'later/services/auth';
import type SubscriptionsService from 'later/services/subscriptions';
import type { SuggestedChangeItem, AddressFields, SuggestedAddressFields, AddressConfig } from 'later/utils/address';
import type { Maybe, UntypedService } from 'shared/types';

export default class AddressService extends Service {
  @service declare addressValidation: UntypedService;
  @service declare auth: AuthService;
  @service declare errors: UntypedService;
  @service declare intl: IntlService;
  @service declare segment: UntypedService;
  @service declare subscriptions: SubscriptionsService;

  DEFAULT_LOADING_STATE_THRESHOLD_IN_MS = 3000;

  /**
   * Set when creating, updating or retrieving a credit card from stipe,
   * Used for determining if the user is in a taxable country.
   *
   */
  @tracked cardCountry: Maybe<string> = null;

  /**
   * True when we could not find any address or could only find a partial address
   * match during validation.
   *
   * False when we found an address, or we are currently editing a new address.
   */
  @tracked isUnsavedAddressNotFound = false;

  @tracked suggestedChanges: TrackedArray<SuggestedChangeItem> = new TrackedArray([]);

  /**
   * Holds saved address values.
   * Can be in two states:
   *  - holds a validated, saved address from the BE.
   *  - has empty values for every key when no saved address on the BE.
   * @property savedAddressFields
   */
  @tracked savedAddressFields: AddressFields = new TrackedObject();

  /**
   * Representation of an address that has not been saved or validated
   * used in the address form and in determining unsaved changes
   */
  @tracked unsavedAddressFields: AddressFields = new TrackedObject();

  get addressConfig(): AddressConfig {
    return ADDRESS_FORM_CONFIG(this.unsavedAddressFields.country, this.auth.currentAccount.rolloutEuVat);
  }

  get isUnsavedAddressVerified(): boolean {
    return Boolean(this.unsavedAddressFields.verified);
  }

  get isSavedAddressVerified(): boolean {
    return Boolean(this.savedAddressFields.verified);
  }
  /**
   * The check for if a user can enter a new address or edit an existing
   */
  get addressCollectionEnabled(): boolean {
    return !this.subscriptions.isMobileSubscription;
  }

  /**
   * The check for if a user can enter a new address or edit an existing address in settings
   * Includes users who have an address, or a subscription that stripe can charge tax for.
   */
  get addressCollectionEnabledInSettings(): boolean {
    if (!this.currentAccount.hasActiveSubscription) {
      return false;
    }

    // Note: based on credit address if present, otherwise the credit card zip code.
    const canChargeTax = this.currentAccount.stripeAutoSalesTaxSupported;

    return (
      this.addressCollectionEnabled &&
      isPresent(this.currentAccount.stripeCustomerId) &&
      (this.isSavedAddressValid || canChargeTax)
    );
  }

  get canAttemptSave(): boolean {
    return (
      !this.isUnsavedAddressNotFound &&
      !this.hasOutstandingSuggestions &&
      !this.validateAddress.isRunning &&
      !this.save.isRunning
    );
  }

  get isFullAddressRequired(): boolean {
    if (this.isSavedAddressValid) {
      return true;
    }

    const selectedCountry = this.unsavedAddressFields?.country ?? this.cardCountry ?? '';

    if (this.currentAccount.rolloutEuVat && ADDRESS_COUNTRIES_TAXABLE_EU.includes(selectedCountry)) {
      return true;
    }

    return ADDRESS_COUNTRIES_TAXABLE.includes(selectedCountry);
  }

  /**
   * We can get fields that are recognized to be incorrect but have no suggestions,
   * this will break our validation as we will show an incorrect suggested address.
   * So we need to verify that any item in suggestedChanges has a value
   */
  get isSuggestedAddressValid(): boolean {
    return (
      this.suggestedChanges.length > 0 &&
      this.suggestedChanges.every((item: SuggestedChangeItem) => isPresent(item.suggested))
    );
  }

  get suggestedAddressFields(): AddressFields {
    const result: Partial<SuggestedAddressFields> = {};
    Object.entries(this.unsavedAddressFields).forEach(([key]) => {
      const suggestion = this.suggestedChanges.find((s: SuggestedChangeItem) => s.key === key);
      if (suggestion) {
        result[key as keyof SuggestedAddressFields] = suggestion.suggested;
      }
    });
    return {
      ...this.filterUnsavedAddressFields(this.unsavedAddressFields),
      ...result,
      verified: this.isSuggestedAddressValid
    };
  }

  get completedAddress(): string {
    return this.#formatAddress(this.savedAddressFields);
  }

  get unsavedAddress(): string {
    return this.#formatAddress(this.filterUnsavedAddressFields(this.unsavedAddressFields));
  }

  get suggestedAddress(): string {
    return this.#formatAddress(this.suggestedAddressFields);
  }

  get countryCode(): Maybe<string> {
    return this.savedAddressFields.country || this.cardCountry;
  }

  get currentAccount(): AccountModel {
    return this.auth.currentAccount;
  }

  get hasOutstandingSuggestions(): boolean {
    return !isEmpty(this.suggestedChanges);
  }

  get hasUnsavedChanges(): boolean {
    return !isEqual(this.unsavedAddressFields, this.savedAddressFields);
  }

  get isSavedAddressValid(): boolean {
    return this.hasRequiredFields(this.savedAddressFields);
  }

  get shouldDisplayTaxAmount(): boolean {
    if (this.isSavedAddressVerified) {
      return ADDRESS_FORM_CONFIG(this.savedAddressFields.country, this.auth.currentAccount.rolloutEuVat)
        .shouldDisplayTaxAmount;
    }
    const shouldDisplayEnteredAddressTax =
      ADDRESS_FORM_CONFIG(this.unsavedAddressFields?.country, this.auth.currentAccount.rolloutEuVat)
        .shouldDisplayTaxAmount && this.isSavedAddressVerified;

    const shouldDisplayCardTax = ADDRESS_FORM_CONFIG(
      this.cardCountry,
      this.auth.currentAccount.rolloutEuVat
    ).shouldDisplayTaxAmount;

    return shouldDisplayEnteredAddressTax || shouldDisplayCardTax;
  }

  /**
   * Address Banner should show to users who are in a region we are collecting tax,
   * but are not yet being taxed on their current subscripiton.
   * When a tax region is enabled in stripe, stripeAutoSalesTaxSupported will be updated
   * based on the current information we have for an account(stripe card or previously existing address)
   *
   */
  get showAddressBanner(): boolean {
    return Boolean(
      this.auth.currentUserModel?.isAccountOwner &&
        this.auth.currentAccount?.hasActiveSubscription &&
        this.auth.currentAccount?.stripeAutoSalesTaxSupported &&
        !this.subscriptions.subscription?.automaticTaxEnabled
    );
  }

  setSelectedCountry(country: string): void {
    this.unsavedAddressFields.country = country;
  }

  /**
   * For Automatically setting country to match card in settings and checkout
   * Country is provided from Stripe Credit Card. The country is saved on the service in order
   * to determine if the full address is required for tax calculation, the country is then set on
   * the form address fields if no existing address exists.
   *
   */
  setSelectedCountryIfNew(country: Maybe<string>): void {
    this.cardCountry = country;
    if (country && !this.isSavedAddressValid) {
      this.unsavedAddressFields.country = country;
    }
  }

  setSuggestion(addressField: keyof SuggestedAddressFields, suggestedValue: string): void {
    this.unsavedAddressFields[addressField] = suggestedValue;
  }

  save = dropTask(async (unsavedAddress: AddressFields) => {
    const fieldsToSave = this.filterUnsavedAddressFields(unsavedAddress);
    try {
      await fetch('/api/v2/addresses.json', {
        method: 'POST',
        body: {
          address: objectMap(fieldsToSave, ([camelCaseKey, value]: [string, string]) => [
            camelToSnake(camelCaseKey),
            value
          ])
        }
      });
      this.savedAddressFields = { ...unsavedAddress };

      this.resetAfterSave();
    } catch (error) {
      this.errors.log('Failed to save address', error);
      throw handleSettingsError(error, 'account.subscription.billing.address', this.intl);
    }
  });

  validateAddress = dropTask(async (unsavedAddress: AddressFields) => {
    const fieldsToValidate = this.filterUnsavedAddressFields(unsavedAddress);
    this.suggestedChanges = new TrackedArray();
    if (this.isSavedAddressValid && isEqual(fieldsToValidate, this.savedAddressFields)) {
      return true;
    }
    try {
      const isValidAddress = await this._validateAddress.perform(fieldsToValidate);
      this.unsavedAddressFields.verified = isValidAddress;
      return isValidAddress;
    } catch (error) {
      this.errors.log('Failed to validate address', error);
      throw handleSettingsError(error, 'account.subscription.billing.address', this.intl);
    }
  });

  _validateAddress = dropTask(async (unsavedAddress: AddressFields) => {
    if (!this.hasRequiredFields(unsavedAddress)) {
      return false;
    }

    const isAccurateAddressRequired = this.isAccurateAddressRequired(unsavedAddress.country);
    const validationSuggestions = await this.addressValidation.validate.perform(
      unsavedAddress,
      isAccurateAddressRequired
    );

    const filteredSuggestions = validationSuggestions?.filter((item: SuggestedChangeItem) => {
      return isPresent(this.addressConfig.fields[item.key as keyof AddressFields]);
    });

    //Note: validationSuggestions will be undefined if not found.
    this.isUnsavedAddressNotFound = isNone(filteredSuggestions);
    if (this.isUnsavedAddressNotFound) {
      return false;
    }

    this.suggestedChanges.addObjects(filteredSuggestions);

    return this.isUnsavedAddressValid(unsavedAddress);
  });

  /**
   * retrieves users saved address, if one exists
   * then sets tracked property denoting if address has previously been completed
   * @returns an object of address fields
   */
  setup = dropTask(async () => {
    const { addresses } = await fetch('/api/v2/addresses.json');

    this.savedAddressFields = assembleAddress(addresses[0]);
    this.resetForm();
  });

  clearSuggestion(addressField: keyof AddressFields): void {
    const index = this.suggestedChanges.findIndex((item: SuggestedChangeItem) => item.key === addressField);
    this.suggestedChanges.removeAt(index);
  }

  clearSuggestions(): void {
    this.suggestedChanges = new TrackedArray();
  }

  /**
   * Filters address fields by referencing the addressConfig, overwriting any values not
   * present in config with an empty string
   * This prevents validation or saving old fields that are hidden in the UI
   * via the address config
   */
  filterUnsavedAddressFields(unsavedAddressFields: AddressFields): AddressFields {
    return objectMap(unsavedAddressFields, ([key, value]: [keyof AddressFields, string]) => [
      key,
      isPresent(this.addressConfig.fields[key]) || key === 'verified' ? value : ''
    ]);
  }

  /**
   * @param countryCode will be empty when creating an address for the first time.
   */
  getRequiredFields(countryCode = ''): Array<keyof AddressFields> {
    const DEFAULT_REQUIRED_FIELDS = [ADDRESS_FIELD_KEYS.CITY, ADDRESS_FIELD_KEYS.COUNTRY, ADDRESS_FIELD_KEYS.LINE_ONE];

    const ifRequiresPostalCode = ADDRESS_COUNTRIES_REQUIRING_POSTAL_CODE.includes(countryCode);
    const ifRequiresState = ADDRESS_COUNTRIES_REQUIRING_STATE.includes(countryCode);

    // Note: Super strict mode.
    if (countryCode === '' || (ifRequiresPostalCode && ifRequiresState)) {
      return [...DEFAULT_REQUIRED_FIELDS, ADDRESS_FIELD_KEYS.POSTAL_CODE, ADDRESS_FIELD_KEYS.STATE];
    }

    // Note: Medium Strict mode. UK Addresses don't require state.
    // there is no need for checking for only ifRequiresState at this time.
    if (ifRequiresPostalCode) {
      return [...DEFAULT_REQUIRED_FIELDS, ADDRESS_FIELD_KEYS.POSTAL_CODE];
    }

    // Note: Super lenient mode. Ensures just the basic info is collected.
    return DEFAULT_REQUIRED_FIELDS;
  }

  hasRequiredFields(addressFields: AddressFields): boolean {
    return isEmpty(
      this.getRequiredFields(addressFields.country).filter(
        (field: keyof AddressFields) => !isPresent(addressFields?.[field])
      )
    );
  }

  /**
   * Countries that we want to make sure we collect a full address.
   * This is based on what countries we will want to collect tax from.
   * Some countries not in this list are smaller and do not have a state/province
   * or postal code in their address.
   * @param countryCode will be empty when creating an address for the first time.
   */
  isAccurateAddressRequired(countryCode = ''): boolean {
    return (
      countryCode === '' ||
      ADDRESS_COUNTRIES_REQUIRING_POSTAL_CODE.includes(countryCode) ||
      ADDRESS_COUNTRIES_REQUIRING_STATE.includes(countryCode)
    );
  }

  isUnsavedAddressValid(unsavedAddress: AddressFields): boolean {
    return !this.isUnsavedAddressNotFound && !this.hasOutstandingSuggestions && this.hasRequiredFields(unsavedAddress);
  }

  /**
   * Ensure that users returning to editing a form they are not presented with old state.
   *
   */
  resetForm(): void {
    this.resetValidation();
    this.resetAddressFields();
    this.cardCountry = null;
  }

  /**
   * Different from a full form reset as we need to keep the card country to calculate tax amounts
   */
  resetAfterSave(): void {
    this.resetValidation();
    this.resetAddressFields();
  }

  resetValidation(): void {
    this.isUnsavedAddressNotFound = false;
  }

  resetAddressFields(): void {
    this.unsavedAddressFields = new TrackedObject({ ...this.savedAddressFields });
    this.suggestedChanges = new TrackedArray([]);
  }

  /**
   * User started to save their address (clicked 'save')
   *
   * @param addressFields unsaved address from form
   * @param location where the event happened. Either checkout or billing
   */
  trackClickedSave(addressFields: AddressFields, location: string): void {
    this.segment.track('address-clicked-save', {
      address_fields: addressFields,
      location
    });
  }

  /**
   * @param addressFields newly saved address from form
   * @param location where the event happened. Either checkout or billing
   */
  trackSavedSuccessfully(addressFields: AddressFields, location: string): void {
    this.segment.track('address-saved-successfully', {
      address_fields: addressFields,
      location
    });
  }

  /**
   * User started to save address, and the spinner appeared because it is taking too long to load. Likely throttling happening.
   *
   * @param addressFields unsaved address from form
   * @param location where the event happened. Only 'checkout' used for now.
   */
  trackViewedLoadingSpinner(addressFields: AddressFields, location: string): void {
    this.segment.track('address-viewed-loading-spinner', {
      address_fields: addressFields,
      location
    });
  }

  /**
   * After user started to save their address they saw a server error.
   * Examples: 5xx or more likely 429 (throttling)
   *
   * Note: This will only capture the last error as fetch will retry a few times.
   * The first 1-2 throttling errors can be looked up in datadog.
   *
   * @param addressFields unsaved address from form
   * @param location where the event happened. Either checkout or billing
   */
  trackViewedServerError(addressFields: AddressFields, location: string): void {
    this.segment.track('address-viewed-server-error', {
      address_fields: addressFields,
      location
    });
  }

  /**
   * After user started to save their address they saw validation error with some fields or address not found.
   *
   * Extra Segment payload:
   * - fieldsEmpty: Example [line2,postalCode]
   * - fieldsWithSuggestions: Example [state,postalCode]
   * - notFound: true if the not found error ends up displaying to user
   *
   * @param addressFields unsaved address from form
   * @param location where the event happened. Either checkout or billing
   */
  trackViewedValidationError(addressFields: AddressFields, location: string): void {
    this.segment.track('address-viewed-validation-error', {
      address_fields: addressFields,
      address_not_found: this.isUnsavedAddressNotFound,
      fields_with_suggestions: this.suggestedChanges.map((item: SuggestedChangeItem) => item.key),
      location,
      missing_required_fields: !this.hasRequiredFields(addressFields)
    });
  }

  #formatAddress(address: AddressFields): string {
    if (address.country && ADDRESS_COUNTRIES_TAXABLE_EU.includes(address.country)) {
      return `${address.line2 || ''} ${address.line1}, ${address.postalCode}, ${address.city}`;
    }
    return `${address.line2 || ''} ${address.line1}, ${address.city}, ${address.state} ${address.postalCode}`;
  }
}

declare module '@ember/service' {
  interface Registry {
    address: AddressService;
  }
}
