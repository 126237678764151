/**
 *
 * This service exists as the point of entry for and
 * monetary transaction that passes through Later
 *
 * @class PaymentService
 * @extends Service
 */

import { assert } from '@ember/debug';
import { reads } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import moment from 'moment-timezone';

import config from 'later/config/environment';
import { fetch, nestedObjectToQueryString } from 'later/utils/fetch';
import { objectSnakeToCamel } from 'shared/utils/object-methods';
import { STRATEGY } from 'shared/utils/retry';

import type RouterService from '@ember/routing/router-service';
import type { StripeCardElement, PaymentMethod } from '@stripe/stripe-js';
import type { SavedTaxId, TaxIdStatus } from 'checkout/types';
import type { Task } from 'ember-concurrency';
import type IntlService from 'ember-intl/services/intl';
import type AccountModel from 'later/models/account';
import type { ProrateOptions } from 'later/models/subscription';
import type SubscriptionModel from 'later/models/subscription';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type CacheService from 'later/services/cache';
import type ErrorsService from 'later/services/errors';
import type SegmentService from 'later/services/segment';
import type StripeService from 'later/services/stripe';
import type { SuggestedAddressFields } from 'later/utils/address';
import type { TRIAL_TYPES } from 'later/utils/constants';
import type { Maybe, ModifyInterface, ValueOfType } from 'shared/types';
import type { PaymentDetails, PaymentObject, Refunds } from 'shared/types/payment';
import type { JsonValue, SnakeCase } from 'type-fest';

type FormattedPaymentObject = ModifyInterface<
  PaymentObject,
  {
    created: string;
    amount: string;
    currency: string;
    canReceipt: boolean;
    refunds: boolean | Refunds;
    status: string;
  }
>;

interface TaxIdResponse {
  taxIds: { value: string; status: TaxIdStatus }[];
  businessName: string;
}

interface TaxAmount {
  amount: number;
  inclusive: boolean;
  tax_rate: {
    id: string;
    object: 'tax_rate';
    active: boolean;
    country: string;
    created: number;
    description: Maybe<string>;
    display_name: Maybe<string>;
    effective_percentage: number;
    inclusive: boolean;
    jurisdiction: string;
    livemode: boolean;
    metadata: Record<string, unknown>;
    percentage: number;
    state: Maybe<string>;
    tax_type: Maybe<string>;
  };
  taxability_reason: Maybe<
    | 'standard_rated'
    | 'reduced_rated'
    | 'zero_rated'
    | 'reverse_charge'
    | 'customer_exempt'
    | 'product_exempt'
    | 'product_exempt_holiday'
    | 'portion_standard_rated'
    | 'portion_reduced_rated'
    | 'portion_product_exempt'
    | 'taxable_basis_reduced'
    | 'not_collecting'
    | 'not_subject_to_tax'
    | 'not_supported'
    | 'proportionally_rated'
  >;
  taxable_amount: number;
}

interface TaxIdData {
  taxId: string;
  businessName: string;
}

interface TaxData {
  total: number;
  tax: number;
  total_tax_amounts: TaxAmount[];
  total_discount_amounts: unknown[];
}

interface CustomerDetails {
  address: SnakeCase<SuggestedAddressFields>;
  vat_number: string;
}
export default class PaymentService extends Service {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare cache: CacheService;
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare segment: SegmentService;
  @service declare stripe: StripeService;

  @reads('auth.currentAccount') declare currentAccount: AccountModel;

  /**
   * Saved on the service for easy retrieval after updates via getter.
   */
  @tracked paymentMethod: Maybe<PaymentMethod>;

  get hasUpcomingExpiringPaymentMethod(): boolean {
    if (!this.currentAccount.paymentMethodExpiresAt) {
      return false;
    }
    const monthTimestamp = moment().endOf('month').unix();
    return this.currentAccount.paymentMethodExpiresAt < monthTimestamp;
  }

  get paymentExpiryCacheKey(): string {
    const userId = this.auth.currentUserModel.id;
    return `${userId}-hasSeenPaymentExpiry`;
  }

  /**
   * Whether or not there is a billing error
   */
  hasErrorLoadingData = false;

  async alertPaymentExpiry(): Promise<void> {
    if (!this.auth.currentAccount.hasActiveSubscription) {
      return;
    }
    if (this.auth.currentUserModel.isAccountOwner) {
      const hasSeenPaymentExpiry = this.cache.retrieve(this.paymentExpiryCacheKey);

      if (!hasSeenPaymentExpiry) {
        const paymentMethod = await this.retrieveCard.perform();
        this.alerts.warning(
          // eslint-disable-next-line @latermedia/later-linting/avoid-numerals-in-name
          this.intl.t('alerts.payment.expiring.description', {
            brand: paymentMethod?.card?.brand,
            last4: paymentMethod?.card?.last4
          }),
          {
            action: () => {
              this.alerts.clear();
              if (this.currentAccount.rolloutPaymentMethods) {
                this.router.transitionTo('account.subscription.billing.payment_method', {
                  queryParams: {
                    update_card_origin: 'in_app_reminder_modal'
                  }
                });
              } else {
                this.router.transitionTo('account.subscription.billing', {
                  queryParams: {
                    update_card_origin: 'in_app_reminder_modal'
                  }
                });
              }
            },
            actionText: this.intl.t('alerts.payment.expiring.button'),
            onDestroy: () =>
              this.cache.add(this.paymentExpiryCacheKey, true, { expiry: this.cache.expiry(1, 'day'), persist: true }),
            preventDuplicates: true,
            title: this.intl.t('alerts.payment.expiring.title')
          }
        );
      }
    } else {
      const hasSeenNonOwnerPaymentExpiry = this.cache.retrieve(this.paymentExpiryCacheKey);
      if (!hasSeenNonOwnerPaymentExpiry) {
        this.alerts.warning(
          this.intl.t('alerts.payment.expiring.description_non_owner', {
            email: this.auth.currentAccount.owner.get('email')
          }),
          {
            title: this.intl.t('alerts.payment.expiring.title'),
            onDestroy: () =>
              this.cache.add(this.paymentExpiryCacheKey, true, {
                expiry: this.cache.expiry(1, 'day'),
                persist: true
              }),
            preventDuplicates: true
          }
        );
      }
    }
  }

  /**
   * Goes through the process of preparing data for, and creating, a subscription
   * to a Later Payment Plan
   */
  processSubscription(args: {
    /**
     * @deprecated after rolloutPaymentMethods
     */
    card?: StripeCardElement;
    paymentMethodId?: string;
    planId: string;
    additionalUsers: number;
    additionalSocialSets: number;
    additionalAiCredits: number;
    totalCost: number;
    couponId?: string;
    trialStartLocation?: string;
    taxIdData?: TaxIdData;
  }): Promise<{ payment: PaymentDetails }> {
    const {
      card,
      paymentMethodId,
      planId,
      additionalUsers,
      additionalSocialSets,
      additionalAiCredits,
      totalCost,
      couponId,
      trialStartLocation,
      taxIdData
    } = args;

    // Note: this assert is only needed while we accept both paymentMethodId and Card while rolloutPaymentMethods is active.
    if (!card && !paymentMethodId) {
      assert('Payment param is required: either card or paymentMethodId.');
    }

    if (paymentMethodId) {
      return this.#createSubscription({
        paymentMethodId,
        planId,
        additionalUsers,
        additionalSocialSets,
        additionalAiCredits,
        totalCost,
        couponId,
        trialStartLocation,
        taxIdData
      });
    }

    //Note: remove after rolloutPaymentMethods
    return this.stripe.createToken(card as StripeCardElement).then((token) => {
      return this.#createSubscription({
        tokenId: token.id,
        planId,
        additionalUsers,
        additionalSocialSets,
        additionalAiCredits,
        totalCost,
        couponId,
        trialStartLocation,
        taxIdData
      });
    });
  }

  /**
   * Changes the subscription plan that an account is on.
   * This is called for any plan changes to an account that isn't on a free plan.
   * Also handles addon changes
   */
  changePlan(args: {
    stripePlanId: string;
    additionalUsers: number;
    additionalSocialSets: number;
    additionalAiCredits: number;
    totalCost?: number;
    couponId?: string;
    stripeToken?: string;
    taxIdData?: TaxIdData;
  }): Promise<{ notice: PaymentDetails }> {
    const {
      stripePlanId,
      additionalUsers,
      additionalSocialSets,
      additionalAiCredits,
      totalCost,
      stripeToken,
      couponId,
      taxIdData
    } = args;

    return fetch(
      `/api/v2/subscriptions/${stripePlanId}`,
      {
        method: 'PATCH',
        headers: { 'Content-type': 'application/json' },
        body: {
          additional_users: additionalUsers,
          additional_social_sets: additionalSocialSets,
          stripeToken,
          additional_ai_credits: additionalAiCredits,
          coupon_id: couponId,
          vat_number: taxIdData?.taxId,
          vat_business: taxIdData?.businessName,
          ...(totalCost ? { recurring_charge_amount: Math.round(totalCost) } : {})
        }
      },
      { intl: null, numRetries: 0, retryStrategy: STRATEGY.DEFAULT, raw: false }
    );
  }

  /**
   * Requests the lists of payments invoiced to this account
   *
   * Returns null (and saves to cache) if the user does not have a stripe card.
   */
  retrieve: Task<Maybe<FormattedPaymentObject[]>, []> = task(async () => {
    try {
      const cachedPayments = this.cache.retrieve<FormattedPaymentObject[] | null>('payments');
      if (cachedPayments) {
        return cachedPayments;
      }

      if (!this.currentAccount?.isStripeCustomer) {
        return;
      }

      const { payments } = await fetch('/api/v2/payments.json', { method: 'GET' });

      const formattedPaymentsObject: FormattedPaymentObject[] | null = payments
        ? payments.data.map((payment: PaymentObject) => {
            const paymentDetails = {
              created: moment.unix(payment.created).format('MMM D, YYYY'),
              amount: (payment.amount / 100.0).toFixed(2),
              currency: payment.currency.toUpperCase(),
              canReceipt: !(payment.status === 'failed' || payment.refunds.total_count > 0),
              refunds: payment.refunds.total_count === 0 ? false : payment.refunds,
              status: payment.status[0].toUpperCase() + payment.status.substr(1)
            };

            return { ...payment, ...paymentDetails };
          })
        : null; // Note: null required in order to save no payments to the cache.

      this.cache.add('payments', formattedPaymentsObject as unknown as JsonValue, {
        expiry: this.cache.expiry(1, 'day')
      });

      return formattedPaymentsObject;
    } catch (error) {
      if (error.code === 422) {
        return;
      }

      if (error.code === 403) {
        return;
      }

      this.errors.log(error);
      throw error;
    }
  });

  /**
   * Requests the details of the credit card we have for the current account
   *
   * Saves card to tracked property.
   *
   * Returns null (and saves to cache) if the user does not have a stripe card.
   */
  retrieveCard: Task<Maybe<PaymentMethod>, []> = task(async () => {
    if (!this.auth.currentAccount.stripeCustomerId) {
      return;
    }

    try {
      const cachedCard = this.cache.retrieve<PaymentMethod | null>('payment_method');
      if (cachedCard || cachedCard === null) {
        return this.paymentMethod;
      }

      const response: { payment_method: JsonValue | null } = await fetch('/api/v2/payment_method.json', {
        method: 'GET'
      });

      this.cache.add('payment_method', response.payment_method, {
        expiry: this.cache.expiry(1, 'hour')
      });

      if (this.auth.currentAccount.hasActiveSubscription && response.payment_method === null) {
        this.errors.log('Failed to load Payment Method when current subscription exists');
      }

      this.paymentMethod = response.payment_method as PaymentMethod | null;
      return this.paymentMethod;
    } catch (error) {
      if (error.code === 422 || error.code === 401) {
        return;
      }
      this.errors.log(error);
      throw error;
    }
  });

  /**
   * Updates a User's stored Payment Method (from payment element)
   * Stripe Payment Method
   *
   * @method updatePaymentMethod
   * @param paymentMethod a stripe Payment Method
   *
   */
  async updatePaymentMethod(
    paymentMethodId: string,
    eventProperties: { location: string; route?: string }
  ): Promise<void> {
    try {
      const defaultEventProperties = {
        route: 'no_previous_prompt'
      };
      await fetch('/api/v2/update_payment_method', {
        method: 'POST',
        body: {
          payment_method_id: paymentMethodId
        }
      });
      this.cache.remove('payment_method');
      this.segment.track('updated-credit-card', { ...defaultEventProperties, ...eventProperties });
    } catch (error) {
      this.errors.log('error updating payment method', error);
      throw error; // Re-throwing the error to be caught by the caller
    }
  }

  retrieveTaxIds: Task<SavedTaxId | undefined, []> = task(async () => {
    if (!this.auth.currentAccount?.stripeCustomerId) {
      return;
    }
    try {
      const response = await fetch('/api/v2/tax_ids');

      const formattedResponse = objectSnakeToCamel(response) as TaxIdResponse;
      if (formattedResponse.taxIds.length === 0) {
        return;
      }
      return {
        businessName: formattedResponse.businessName,
        value: formattedResponse.taxIds?.[0]?.value,
        status: formattedResponse.taxIds?.[0]?.status
      };
    } catch (error) {
      this.errors.log('error fetching tax id', error);
      return;
    }
  });

  /**
   * Starts a sourceless (credit cardless) trial for the current user.
   */
  startSourcelessTrial: Task<PaymentDetails, [string, ValueOfType<typeof TRIAL_TYPES>]> = task(
    async (stripePlanId: string, trialType: ValueOfType<typeof TRIAL_TYPES>) => {
      try {
        return await fetch('/api/v2/subscriptions/', {
          method: 'POST',
          body: {
            plan_id: stripePlanId,
            trial_type: trialType
          }
        });
      } catch (error) {
        this.errors.log('Credit cardless trial start failed', error);
        throw error;
      }
    }
  );

  /**
   * Requests the invoice that a user would be charged if they
   * checkout with the requested subscription plan and addons.
   */
  retrieveProration(args: {
    subscription: SubscriptionModel;
    stripePlanId: string;
    additionalUsers: number;
    additionalSocialSets: number;
    additionalAiCredits: number;
    taxId?: string;
  }): ReturnType<SubscriptionModel['prorate']> {
    const { subscription, stripePlanId, additionalUsers, additionalSocialSets, additionalAiCredits, taxId } = args;
    const prorateArgs: ProrateOptions = {
      plan_id: stripePlanId,
      additional_users: additionalUsers,
      additional_social_sets: additionalSocialSets,
      additional_ai_credits: additionalAiCredits
    };
    if (taxId) {
      prorateArgs.tax_id = taxId;
    }

    return subscription.prorate(prorateArgs);
  }

  retrieveStripeTax(args: {
    stripePlanId: string;
    additionalUsers: number;
    additionalSocialSets: number;
    additionalAiCredits: number;
    customerDetails: CustomerDetails;
    couponId?: string;
  }): TaxData {
    const { stripePlanId, additionalUsers, additionalSocialSets, additionalAiCredits, customerDetails, couponId } =
      args;

    const payload = {
      plan_id: stripePlanId,
      additional_users: additionalUsers,
      additional_social_sets: additionalSocialSets,
      additional_ai_credits: additionalAiCredits,
      customer_details: customerDetails,
      coupon_id: couponId
    };

    return fetch(
      `/api/v2/subscriptions_preview?${nestedObjectToQueryString(payload)}`,
      {},
      { intl: null, numRetries: 0, retryStrategy: STRATEGY.DEFAULT, raw: false }
    );
  }

  /**
   * Creates a Subscription Record
   */
  async #createSubscription(args: {
    /**
     * @deprecated after rolloutPaymentMethods
     */
    tokenId?: string;
    paymentMethodId?: string;
    planId: string;
    additionalUsers: number;
    additionalSocialSets: number;
    additionalAiCredits: number;
    totalCost: number;
    couponId?: string;
    trialStartLocation?: string;
    taxIdData?: TaxIdData;
  }): Promise<{ payment: PaymentDetails }> {
    const {
      tokenId,
      paymentMethodId,
      planId,
      additionalUsers,
      additionalSocialSets,
      additionalAiCredits,
      totalCost,
      couponId,
      trialStartLocation,
      taxIdData
    } = args;

    // Note: this assert is only needed while we accept both paymentMethodId and Card while rolloutPaymentMethods is active.
    if (!tokenId && !paymentMethodId) {
      assert('Payment param is required: either tokenId or paymentMethodId.');
    }

    // Note: Google script for recaptcha is loaded in lib/checkout/addon/components/seamless-checkout/checkout-modal.ts constructor
    // https://developers.google.com/recaptcha/docs/v3
    const recaptcha_token = await grecaptcha?.execute(config.APP.googleRecaptchaSiteKey, { action: 'submit' });

    return fetch(
      '/api/v2/subscriptions',
      {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: {
          plan_id: planId,
          //Note: remove stripeToken after rolloutPaymentMethods
          stripeToken: tokenId,
          payment_method_id: paymentMethodId,
          coupon_id: couponId,
          recaptcha_token,
          additional_users: additionalUsers,
          additional_social_sets: additionalSocialSets,
          additional_ai_credits: additionalAiCredits,
          google_client_id: this.segment.getClientId(),
          recurring_charge_amount: Math.round(totalCost),
          trial_start_location: trialStartLocation,
          vat_number: taxIdData?.taxId,
          vat_business: taxIdData?.businessName
        }
      },
      { intl: null, numRetries: 0, retryStrategy: STRATEGY.DEFAULT, raw: false }
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    payment: PaymentService;
  }
}
