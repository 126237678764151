import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

import { HttpMethod } from 'later/utils/constants';
import { fetch, objectToQueryString } from 'later/utils/fetch';
import { MavelyProfileIndex, type MavelyProfile } from 'later/utils/mavely-profile-index';
import { STRATEGY } from 'shared/utils/retry';

import type RouterService from '@ember/routing/router-service';
import type StoreService from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type GroupModel from 'later/models/group';
import type SocialIdentityModel from 'later/models/social-identity';
import type AlertsService from 'later/services/alerts';
import type AuthService from 'later/services/auth';
import type DialogManagerService from 'later/services/dialog-manager';
import type ErrorsService from 'later/services/errors';
import type PopupInstance from 'shared/lib/popup-instance';
import type { PopupWindowFeatures } from 'shared/lib/popup-instance';
import type PopupManagerService from 'shared/services/shared/popup-manager';

type FetchProfileParamKeys = 'group_id' | 'social_identity_id';
type FetchProfileParams = { [K in FetchProfileParamKeys]?: string };

export const MAVELY_AUTH_POPUP_NAME = 'mavely_oauth';

/**
 * Service to handle authenticating Mavely Account.
 */
export default class MavelyAuthService extends Service {
  @service declare alerts: AlertsService;
  @service declare auth: AuthService;
  @service declare dialogManager: DialogManagerService;
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service('shared/popup-manager') declare popupManager: PopupManagerService;
  @service declare store: StoreService;

  /**
   * Stored by social identity ID, where Social Identity is the key. Contains authenticated profiles and expired profiles
   */
  @tracked mavelyProfiles = new MavelyProfileIndex();

  /**
   * Returns all connected profiles on current group and removes duplicates
   */
  get connectedMavelyProfilesOnCurrentGroup(): MavelyProfile[] {
    return this.mavelyProfiles.authenticated.filter((mavelyProfile) =>
      mavelyProfile.groupIds.includes(this.currentGroup.id)
    );
  }

  get currentGroup(): GroupModel {
    return this.auth.currentGroup;
  }

  get hasMavelyProfileOnCurrentGroup(): boolean {
    return Boolean(this.connectedMavelyProfilesOnCurrentGroup.length);
  }

  /**
   * Handles oAuth flow including creating popup and receiving mavely profile id
   */
  authenticate = task(async (socialIdentity?: SocialIdentityModel) => {
    const hasSocialIdentity = !!socialIdentity;
    const profileId = await this.handleAuthenticationPopup.perform(socialIdentity);

    if (!hasSocialIdentity) {
      //Note: if the user did not have any on the current group, backend had to create a social identity
      await this.store.findAll('social-identity');
    }

    if (hasSocialIdentity) {
      //Note: the endpoint does not return the profile info, only the ID, so we need to fetch the profile after completion
      await this.fetchProfiles.perform(socialIdentity);
    } else {
      await this.fetchProfilesOnGroup.perform(this.currentGroup);
    }
    return profileId;
  });

  disconnect = task(async ({ groupId, profile }: { groupId: string; profile: MavelyProfile }) => {
    const didConfirm = await this.dialogManager.confirmation(this.intl.t('account.mavely.disconnect.confirm.title'), {
      description: this.intl.t('account.mavely.disconnect.confirm.description', { account: profile.email }),
      confirmButton: this.intl.t('shared_words.disconnect'),
      cancelButton: this.intl.t('shared_words.cancel')
    });

    if (!didConfirm) {
      return;
    }

    const queryParams = { profile_id: profile.id, group_id: groupId };
    try {
      await fetch(`api/v2/mavely_profiles/disconnect${objectToQueryString(queryParams)}`, {
        method: HttpMethod.Delete
      });

      this.mavelyProfiles.remove(profile.id);

      this.alerts.success(
        this.intl.t('account.mavely.disconnect.success.description', {
          account: profile.email
        }),
        {
          title: this.intl.t('account.mavely.disconnect.success.title')
        }
      );
    } catch {
      this.alerts.alert(this.intl.t('shared_phrases.something_went_wrong'), {
        title: this.intl.t('account.mavely.disconnect.failure.title', { account: profile.email })
      });
    }
  });

  fetchProfiles = task(async (socialIdentity: SocialIdentityModel) => {
    const queryParams = { social_identity_id: socialIdentity.get('id') };
    await this._fetchProfiles.perform(socialIdentity, queryParams);
  });

  fetchProfilesOnGroup = task(async (group: GroupModel) => {
    const queryParams = { group_id: group.get('id') };
    await this._fetchProfiles.perform(group, queryParams);
  });

  refresh = task(async (profile: MavelyProfile) => {
    try {
      const refreshedProfileId = await this.handleAuthenticationPopup.linked().perform();

      const didAuthenticateWithDifferentProfile = profile.id !== refreshedProfileId;
      if (didAuthenticateWithDifferentProfile) {
        this.alerts.alert(this.intl.t('alerts.mavely.refresh.failure.description', { account: profile.email }), {
          title: this.intl.t('alerts.mavely.refresh.failure.title', { account: profile.email })
        });

        this.alerts.success(this.intl.t('alerts.linkinbio.blocks.mavely.connection.success.description'), {
          title: this.intl.t('alerts.linkinbio.blocks.mavely.connection.success.title')
        });
      } else {
        this.mavelyProfiles.remove(profile.id);

        this.alerts.success(this.intl.t('alerts.mavely.refresh.success.description', { account: profile.email }), {
          title: this.intl.t('alerts.mavely.refresh.success.title')
        });
      }
      await this.fetchProfilesOnGroup.linked().perform(this.auth.currentGroup);
    } catch (error) {
      this.alerts.alert(this.intl.t('shared_phrases.something_went_wrong'), {
        title: this.intl.t('alerts.linkinbio.blocks.mavely.connection.failure.title')
      });
      this.errors.log(error);
    }
  });

  _fetchProfiles = task(async (model: SocialIdentityModel | GroupModel, params: FetchProfileParams) => {
    try {
      const url = `/api/v2/mavely_profiles${objectToQueryString(params)}`;
      const { data } = await fetch(url, {}, { intl: null, numRetries: 1, retryStrategy: STRATEGY.DEFAULT, raw: false });

      if (data) {
        this.mavelyProfiles.add(model, data);
      }
    } catch (error) {
      this.alerts.alert(this.intl.t('shared_phrases.something_went_wrong'), {
        title: this.intl.t('alerts.linkinbio.blocks.mavely.connection.failure.title')
      });
      this.errors.log(error);
    }
  });

  handleAuthenticationPopup = task(async (socialIdentity?: SocialIdentityModel) => {
    const queryParams = {
      redirect_path: this.router.urlFor('user.profile-refresh-popup', MAVELY_AUTH_POPUP_NAME).slice(1),
      authorizing_object_id: this.currentGroup.get('id'),
      authorizing_object_type: 'Group'
    };

    if (socialIdentity) {
      queryParams.authorizing_object_type = 'SocialIdentity';
      queryParams.authorizing_object_id = socialIdentity.get('id');
    }

    const popup = this.#makePopupWindow(MAVELY_AUTH_POPUP_NAME, `/oauth/mavely${objectToQueryString(queryParams)}`);

    try {
      const parentWidth = window.innerWidth;
      const parentHeight = window.innerHeight;
      const windowFeatures = {
        popup: true,
        width: 800,
        height: 600,
        left: (parentWidth - 800) / 2,
        top: (parentHeight - 600) / 2
      } satisfies PopupWindowFeatures;
      const data = await popup.open({ features: windowFeatures });

      return data?.mavely_profile_id;
    } catch (error) {
      this.alerts.alert(this.intl.t('influencers.mavely.connect.error_msg'));
      throw error;
    }
  });

  #makePopupWindow(name: string, url: string): PopupInstance<{ mavely_profile_id: string }, true> {
    return this.popupManager.createPopup(name, { url });
  }
}

declare module '@ember/service' {
  interface Registry {
    'mavely-auth': MavelyAuthService;
  }
}
