import { A } from '@ember/array';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { readOnly, sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isNone, isBlank } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';

export default class ScheduleController extends Controller {
  @service alerts;
  @service auth;
  @service credentialStatus;
  @service intl;
  @service mediaLibrary;
  @service router;
  @service selectedSocialProfiles;
  @service schedule;
  @service('social/connect-profiles') connectProfiles;

  @tracked show_linkedin_selector = null;
  @tracked starredMedia = null;
  @tracked targetDate = null;
  @tracked showCredentialsWizard = false;
  @tracked profileToRefresh = null;
  @tracked displayAdvanced = true;

  // Note: The 'targetDate' query param is consumed by the 'calendar' route
  queryParams = ['show_linkedin_selector', 'targetDate'];

  allMediaItems = A([]);
  devices = A([]);
  showUnscheduled = true;
  showScheduled = false;
  showPosted = false;
  postsSortingDesc = ['createdTime:desc'];
  mediaItemSortingDesc = ['createdTime:desc'];

  @sort('posts', 'postsSortingDesc') orderedPosts;
  @sort('mediaItems', 'mediaItemSortingDesc') orderedMediaItems;

  @readOnly('auth.currentUserModel.hasValidLinkedinUserToken') hasValidLinkedinUserToken;
  @readOnly('auth.currentAccount.owner.email') ownerEmail;
  @readOnly('credentialStatus.brokenProfiles') brokenProfiles;
  @readOnly('credentialStatus.displayModal') showAdvancedCredentialStatusModal;
  @readOnly('schedule.isDragging') isDragging;
  @readOnly('schedule.sideLibraryBehavior') sideLibraryBehavior;

  updateShowProfileProperty = (property, value) => {
    this.connectProfiles.updateProperty(property, value);
  };

  get showLinkedinSelector() {
    return this.show_linkedin_selector === 'true' ? true : false;
  }

  get showStarredMedia() {
    return this.mediaLibrary.showStarredMedia;
  }

  get selectedAssets() {
    return this.mediaLibrary.selectedMedia;
  }

  get canAccessLinkedin() {
    return this.currentAccount.canAccessLinkedin;
  }

  get canAutoSchedule() {
    return this.currentAccount.canAutoSchedule;
  }

  get canTiktok() {
    return this.currentAccount.canTiktok;
  }

  get currentGroup() {
    return this.auth.currentGroup;
  }

  get currentAccount() {
    return this.auth.currentAccount;
  }

  get currentUserModel() {
    return this.auth.currentUserModel;
  }

  get groupInstagramProfiles() {
    return this.currentGroup.socialProfiles.filter((profile) => profile.isInstagram);
  }

  get hasProfiles() {
    return this.currentGroup.socialProfiles.length > 0;
  }

  get hasTimeSlots() {
    return this.singleSocialProfile?.hasUserTimeSlots;
  }

  get isViewingMultiple() {
    return this.selectedSocialProfiles?.hasMultipleSelected;
  }

  get isQuickScheduleWizardVisible() {
    return this.schedule.isQuickScheduleWizardVisible;
  }

  get singleSocialProfile() {
    return this.selectedSocialProfiles?.firstProfile;
  }

  get switchableSocialProfilesType() {
    if (this.sideLibraryBehavior === 'preview') {
      return 'instagram';
    }
    return null;
  }

  get enableAutoSchedule() {
    if (this.isViewingMultiple) {
      return false;
    } else if (isNone(this.sideLibraryBehavior)) {
      return false;
    }
    return this.hasTimeSlots && this.singleSocialProfile?.hasPublishingMethod;
  }

  get showAutoScheduleDropZone() {
    return this.isDragging && this.enableAutoSchedule && !isBlank(this.dropZoneText) && !this.isReelsPreviewGrid;
  }

  get isReelsPreviewGrid() {
    return this.router.currentRouteName === 'cluster.schedule.preview-grid.reels';
  }

  get canCreateTimeSlots() {
    return (
      !this.singleSocialProfile?.isPinterest &&
      !this.singleSocialProfile?.isYoutube &&
      this.canAutoSchedule &&
      !this.isViewingMultiple
    );
  }

  get dropZoneText() {
    if (this.sideLibraryBehavior === 'calendar') {
      return this.intl.t('calendar.auto_schedule_drop');
    } else if (this.sideLibraryBehavior === 'preview') {
      return this.intl.t('preview_grid.add_to_preview_drop');
    }
    return '';
  }

  get canCreateFirstTimeSlot() {
    return this.get('canCreateTimeSlots') && !this.get('hasTimeSlots');
  }

  /**
   *
   * Returns true or false to signify if the user has skipped connecting a social profile after signup.
   *
   * @returns {Boolean}
   */

  get showAddTiktokProfileModal() {
    return this.connectProfiles.showAddTiktokProfileModal;
  }

  get showFacebookSelector() {
    return this.connectProfiles.showFacebookSelector;
  }

  get mediaItems() {
    return this.get('allMediaItems').filter(
      (mediaItem) =>
        mediaItem.get('approved') &&
        mediaItem.get('active') &&
        mediaItem.get('isLoaded') &&
        mediaItem.belongsTo('group').id() === this.get('currentGroup.id')
    );
  }

  get showAnalyticsHighlights() {
    return false;
    // Note: Whistler to review if this component can be repurposed for high performance posts
    // return (
    //   this.currentAccount.canInstagramPaidAnalytics &&
    //   !this.selectedSocialProfiles.hasMultipleSelected &&
    //   this.singleSocialProfile?.isInstagram &&
    //   this.calendarConfig.calendarView === WEEK &&
    //   this.sideLibraryBehavior === 'calendar' &&
    //   !this.currentAccount.isOnLegacyPlan
    // );
  }

  addLinkedinProfile = dropTask(async (id) => {
    await this.connectProfiles.addLinkedinProfile.perform({
      group: this.currentGroup,
      id,
      path: 'cluster.schedule.calendar'
    });
  });

  @action
  addSelection(mediaItem) {
    if (mediaItem.get('mediaType') === 'gif' && this.sideLibraryBehavior === 'preview') {
      this.alerts.warning(this.intl.t('alerts.preview_grid.gif.message'), {
        title: this.intl.t('alerts.preview_grid.gif.title')
      });
    } else {
      this.selectedAssets.addObject(mediaItem);
    }
  }

  @action
  assignDropData(draggedElement, event) {
    if (draggedElement.getAttribute('data-media-item-id') === 'text') {
      this.schedule.isDraggingEmptyPost = true;
    } else if (this.selectedAssets.length > 1) {
      const ids = this.selectedAssets.map((item) => item.get('id')).join();
      event.dataTransfer.setData('text/plain', ids);
    } else {
      event.dataTransfer.setData('text/plain', draggedElement.getAttribute('data-media-item-id'));
    }
  }

  @action
  closeAdvancedCredentials() {
    this.displayAdvanced = false;
    this.credentialStatus.successfulRefresh = false;

    if (this.credentialStatus.hasMissingFeatures) {
      this.brokenProfiles.forEach((profile) => {
        this.#displayBrokenProfileAlert(profile);
      });
    }
  }

  @action
  removeSelection(mediaItem) {
    this.selectedAssets.removeObject(mediaItem);
  }

  @action
  deselectAll() {
    this.clearSelection();
  }

  @action
  droppedMediaItems(droppedData) {
    const self = this;
    const ids = droppedData?.split(',').filter((id) => id);

    if (ids?.length) {
      if (this.sideLibraryBehavior === 'calendar') {
        const mediaItems = A([]);
        ids.forEach((id) => {
          const mediaItem = self.store.peekRecord('media-item', id);
          mediaItems.push(mediaItem);
        });

        this.send('autoScheduleMediaItems', mediaItems, this.singleSocialProfile);
      } else if (this.sideLibraryBehavior === 'preview') {
        this.send('addToPreview', ids);
      }
    }
    this.send('deselectAll');
  }

  @action
  async addFacebookProfile(uid, token, scopes) {
    await this.connectProfiles.addFacebookProfile({
      group: this.currentGroup,
      uid,
      token,
      scopes,
      path: 'cluster.schedule.calendar'
    });
  }

  @action
  async addTiktokProfile(name) {
    await this.connectProfiles.addTiktokProfile({
      name,
      group: this.currentGroup,
      path: 'account.groups.group.social_profiles'
    });
  }

  @action
  hideQuickScheduleWizard() {
    this.schedule.isQuickScheduleWizardVisible = false;
  }

  @action
  openCredentialsWizard(socialProfile) {
    if (socialProfile?.isProfessional) {
      this.profileToRefresh = socialProfile;
      this.showCredentialsWizard = true;
    }
  }

  @action
  openMediaItem(mediaItem) {
    const mediaItemId = mediaItem.get('id');

    switch (this.sideLibraryBehavior) {
      case 'calendar':
        this.router.transitionTo('cluster.schedule.calendar.media', mediaItemId);
        break;
      case 'list':
        this.router.transitionTo('cluster.schedule.list.media', mediaItemId);
        break;
      case 'preview':
        this.router.transitionTo('cluster.schedule.preview-grid.media-item', mediaItemId);
        break;
      default:
        this.router.transitionTo('cluster.schedule.calendar');
    }
  }

  @action
  scheduleMultiImagePost(mediaItems, location) {
    this.schedule.startSchedulingNewPost(mediaItems, location);
  }

  @action
  selectedAssetsAction() {
    this.autoScheduleSelectedAssets();
    this.send('deselectAll');
  }

  @action
  setIsDragging(bool) {
    this.set('schedule.isDragging', bool);
  }

  @action
  closeLinkedInSelector() {
    this.show_linkedin_selector = false;
  }

  reset() {
    this.mediaLibrary.showStarredMedia = false;
    this.clearSelection();
  }

  clearSelection() {
    this.selectedAssets.clear();
  }

  autoScheduleSelectedAssets() {
    const mediaItems = [...this.selectedAssets];
    if (this.sideLibraryBehavior === 'calendar') {
      this.send('autoScheduleMediaItems', mediaItems, this.singleSocialProfile);
    } else if (this.sideLibraryBehavior === 'preview') {
      this.send('addToPreview', mediaItems.mapBy('id'));
    }
  }

  #displayBrokenProfileAlert(profile) {
    this.alerts.alert(
      this.intl.t('alerts.errors.auto_publish.expired_token.message', {
        account_name: profile.nickname
      }),
      {
        title: this.intl.t('shared_phrases.refresh_your_connection'),
        action: () => this.credentialStatus.connectBusinessAccount.perform(profile, 'calendar'),
        actionText: this.intl.t('shared_words.refresh'),
        preventDuplicates: false
      }
    );
  }
}
