import Service, { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

import { HttpMethod } from 'later/utils/constants';

import type { Task } from 'ember-concurrency';
import type ErrorsService from 'later/services/errors';

export enum MavelyLinksOrderByOptions {
  Link = 'link',
  MetaImage = 'meta_image',
  MetaTitle = 'meta_title',
  MetaDescription = 'meta_description',
  MetaSiteName = 'meta_site_name',
  OriginalUrl = 'original_url'
}

export interface MavelyLink {
  created_at: string;
  id: string;
  link: string;
  original_url: string;
  meta_url: string;
  meta_title: string;
  meta_image: string;
  meta_description: string;
  brand: string;
}

export interface MavelyLinksResponse {
  count: number;
  has_next_page: boolean;
  links: MavelyLink[];
}

type FetchLinksRequestTask = Task<MavelyLinksResponse, [Record<string, never>]>;

type FetchMavelyLinkTask = Task<MavelyLink | undefined, [mavelyProfileId: string, id: string]>;

type FetchMavelyLinksTask = Task<
  MavelyLinksResponse,
  [mavelyProfileId: string, offset?: number, limit?: number, orderBy?: MavelyLinksOrderByOptions, search?: string]
>;

/**
 * This service handles fetching data for mavely links
 **/
export default class MavelyLinksService extends Service {
  @service declare errors: ErrorsService;

  standardMaximumLinksPerRequest = 20;

  /**
   * Stored by mavely profile ID, where profile ID is the key and a MavelyLinksResponse is the value
   */
  @tracked profileData: Record<string, MavelyLinksResponse> = {};

  /**
   * Fetches a single mavely link for a given mavely profile
   * In the case that the link doesn't exist for the given profile,
   * a MavelyLinksResponse will be returned with an empty links array
   *
   * @param mavelyProfileId The id of the mavely profile to retrieve links for
   * @param id The id of the link to retrieve
   *
   */
  fetchMavelyLink: FetchMavelyLinkTask = task(async (mavelyProfileId, id) => {
    const urlParams = {
      profile_id: mavelyProfileId,
      id
    };

    const existingLink = this.profileData[mavelyProfileId]?.links.find((link) => link.id === id);
    if (existingLink) {
      return existingLink;
    }

    const mavelyLinksData = await this._fetch.perform(urlParams as unknown as Record<string, never>);
    return mavelyLinksData?.links[0];
  });

  /**
   * Fetches all mavely links for a given mavely profile
   * Updates the locally stored profileData record
   * There is one required property, and the rest are optional.
   *
   * @param mavelyProfileId The id of the mavely profile to retrieve links for
   * @param offset The number of links to start fetching links from (used for pagination purposes)
   * @param limit The number of links for BE to send back (used in conjunction with offset for pagination)
   * @param orderBy The link attribute to order returned links by (default order returned is by created_at date where most recent is first)
   * @param search The search query to match links against: filters for partial match on meta_title, meta_description, and original_url
   *
   */
  fetchMavelyLinks: FetchMavelyLinksTask = task(async (mavelyProfileId, offset, limit, orderBy, search) => {
    const urlParams = {
      profile_id: mavelyProfileId,
      ...(offset && { offset: offset.toString() }),
      ...(limit && { limit: limit.toString() }),
      ...(orderBy && { order_by: orderBy }),
      ...(search && { search })
    };

    if (!search) {
      const existingLinks = this.#getExistingLinks(mavelyProfileId, offset, limit, orderBy);
      if (existingLinks) {
        return existingLinks;
      }
    }

    const mavelyLinksData = await this._fetch.perform(urlParams);

    if (!search) {
      //Update locally cached data
      const previouslyFetchedLinksForProfile = this.profileData[mavelyProfileId]?.links;
      if (previouslyFetchedLinksForProfile) {
        const numberOfPreviouslyFetchedLinks = this.profileData[mavelyProfileId]?.count;
        this.profileData[mavelyProfileId].has_next_page = mavelyLinksData.has_next_page;
        this.profileData[mavelyProfileId].links = [...previouslyFetchedLinksForProfile, ...mavelyLinksData.links];
        this.profileData[mavelyProfileId].count = numberOfPreviouslyFetchedLinks + mavelyLinksData.count;
        return this.profileData[mavelyProfileId];
      }

      this.profileData[mavelyProfileId] = mavelyLinksData;
    }

    return mavelyLinksData;
  });

  private _fetch: FetchLinksRequestTask = task(async (urlParams) => {
    try {
      const response = await fetch('/api/v2/affiliate_links/links?' + new URLSearchParams(urlParams).toString(), {
        method: HttpMethod.Get
      });

      if (!response.ok) {
        if ('id' in urlParams) {
          throw new Error(`Failed to fetch mavely link ${urlParams.id} for profile: ${urlParams.profile_id}`);
        }
        throw new Error(`Failed to fetch mavely links for profile: ${urlParams.profile_id}`);
      }

      return await response.json();
    } catch (error) {
      this.errors.log(error);
    }
  });

  #getExistingLinks(
    mavelyProfileId: string,
    offset?: number,
    limit?: number,
    orderBy?: string
  ): MavelyLinksResponse | undefined {
    const existingFetchedLinks = this.profileData[mavelyProfileId];
    const isCustomOrder = Boolean(orderBy);
    const isFetchingAllExistingLinks = offset === 0;
    if (existingFetchedLinks && !isCustomOrder) {
      if (isFetchingAllExistingLinks) {
        return this.profileData[mavelyProfileId];
      }
      const hasEnoughLinksFetched =
        isPresent(limit) && isPresent(offset)
          ? (limit as number) + (offset as number) <= existingFetchedLinks?.links.length
          : true;
      if (hasEnoughLinksFetched) {
        return { ...existingFetchedLinks, links: existingFetchedLinks.links.slice(offset, (limit as number) - 1) };
      }
      return undefined;
    }
    return undefined;
  }
}

declare module '@ember/service' {
  interface Registry {
    'mavely-links': MavelyLinksService;
  }
}
