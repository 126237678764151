import Service, { service } from '@ember/service';
import { isPresent, isNone } from '@ember/utils';
import { dropTask } from 'ember-concurrency';

import { ADDRESS_FIELD_KEYS } from 'later/utils/constants';
import { fetch, objectToQueryString } from 'later/utils/fetch';

import type JWTService from 'later/services/jwt';
import type LaterConfigService from 'later/services/later-config';
import type { AddressFields, Field, SmartyResponse } from 'later/utils/address';

export default class AddressValidationService extends Service {
  @service declare laterConfig: LaterConfigService;
  @service declare jwt: JWTService;

  /**
   * Dictionary for converting FE values to validation API values
   * APISuggestionKey is for the suggested fields returned from the api that have a slightly different naming convention
   *
   * https://www.smarty.com/docs/cloud/international-street-api#components
   */
  fieldsDictionary: Field[] = [
    { FEKey: ADDRESS_FIELD_KEYS.CITY, APIKey: 'locality' },
    { FEKey: ADDRESS_FIELD_KEYS.COUNTRY, APIKey: 'country' },
    { FEKey: ADDRESS_FIELD_KEYS.LINE_ONE, APIKey: 'address1' },
    { FEKey: ADDRESS_FIELD_KEYS.STATE, APIKey: 'administrative_area' },
    {
      FEKey: ADDRESS_FIELD_KEYS.POSTAL_CODE,
      APIKey: 'postal_code',
      APISuggestionKey: 'postal_code_short'
    }
  ] as const;

  /**
   * Key that indicates that field is perfectly validated.
   * Address line 1 will only return this value if all other fields are perfectly validated.
   *
   * https://www.smarty.com/docs/cloud/international-street-api#changes
   */
  NO_CHANGE_NEEDED = 'Verified-NoChange';

  /**
   * Indicates if a field returned from API is completely garbled in which case we could not offer suggestions to the user
   *
   * https://www.smarty.com/docs/cloud/international-street-api#changes
   */
  NOT_FOUND = ['Unrecognized', 'Identified-ContextChange'];

  /**
   * Indicates if a field did not validate and needs changes.
   * Is not included on US addresses.

   * https://www.smarty.com/docs/cloud/international-street-api#changes
   */
  CHANGES_NEEDED = [
    'Verified-AliasChange',
    'Verified-SmallChange',
    'Verified-LargeChange',
    'Added',
    'Identified-AliasChange',
    'Identified-ContextChange',
    'Unrecognized'
  ];

  /**
   * Indicates if an address can be verified down to premise (building) level.
   * Usually the case if an apartment number is not provided.
   * If an address has a verification level of partial and a precision level of 'Premise',
   * smarty deems it deliverable.
   *
   * https://www.smarty.com/docs/cloud/international-street-api#analysis
   */
  PRECISION_LEVEL_PREMISE = 'Premise';

  /**
   * Address match is verified to the delivery point (i.e., building, sub-building, or mailbox)
   *
   * https://www.smarty.com/docs/cloud/international-street-api#analysis
   */
  PRECISION_LEVEL_DELIVERY_POINT = 'DeliveryPoint';

  /**
   * Indicates if an address includes a PO Box. We can be a bit more lenient on these.
   *
   * https://www.smarty.com/docs/cloud/international-street-api#analysis
   */
  BOX_TYPE_PO_BOX = 'PO Box';

  /**
   * Indicates if a whole address is found or not, though it may still require field level changes.
   * "Ambiguous" verification is allowed because it would be due to a missing company name, which there is no input for.
   * Partial is accepted as long as the precision level is 'Premise'
   *
   *  https://www.smarty.com/docs/cloud/international-street-api#analysis
   */
  VERIFICATION_LEVEL = {
    NONE: 'None',
    PARTIAL: 'Partial',
    AMBIGUOUS: 'Ambiguous',
    VERIFIED: 'Verified'
  };

  /**
   * Make request to Smarty API via Jolteon proxy.
   * @returns Raw Smarty response. Array of found matches, or empty if no address found.
   */
  makeRequest = dropTask(async (addressFields: AddressFields): Promise<SmartyResponse[]> => {
    const token = await this.jwt.fetchToken();
    const normalized = this.#normalizeAddress(addressFields);

    const endpoint = `${this.laterConfig.igProxy}/secure-proxy/smarty/verify${objectToQueryString(normalized)}`;
    const config = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    return await fetch(endpoint, config);
  });

  /**
   * Validate address via Smarty api, and return any changes.
   * isAccurateAddressRequired:
   *  - True if we want to make sure the address validates to a premise (Example: countries we want to charge tax)
   *  - False for countries where smarty can not validate to a premise due to no building number in address (Example: some addresses in Bahamas)
   * @returns Array of required changes, or an Empty array if valid and no changes, or undefined if address not found.
   */
  validate = dropTask(async (addressFields: AddressFields, isAccurateAddressRequired = true) => {
    const foundAddresses = await this.makeRequest.perform(addressFields);

    if (!isPresent(foundAddresses)) {
      return;
    }

    const firstValidAddress = foundAddresses.find((foundAddress) => {
      return this.#isAnalysisValid(foundAddress, isAccurateAddressRequired);
    });

    if (isPresent(firstValidAddress) && firstValidAddress) {
      return this.#requiredChanges(firstValidAddress, addressFields);
    }

    return;
  });

  #isAnalysisValid(foundAddress: SmartyResponse, isAccurateAddressRequired: boolean): boolean {
    const { analysis, components } = foundAddress;
    const { verification_status: verificationStatus, address_precision: addressPrecision } = analysis;

    //Note: bad addresses may return Ambiguous and Delivery Point if PO box was added by smarty.
    // Example: "Address, Vancouver, BC, V3C 3P1"
    if (verificationStatus === this.VERIFICATION_LEVEL.AMBIGUOUS && analysis.changes.components.post_box === 'Added') {
      return false;
    }

    const isPOBox =
      verificationStatus === this.VERIFICATION_LEVEL.PARTIAL && components?.post_box_type === this.BOX_TYPE_PO_BOX;

    const isPartialPremise = Boolean(
      this.VERIFICATION_LEVEL.PARTIAL && addressPrecision === this.PRECISION_LEVEL_PREMISE
    );
    const isAmbiguousDeliveryPoint = Boolean(
      this.VERIFICATION_LEVEL.AMBIGUOUS && addressPrecision === this.PRECISION_LEVEL_DELIVERY_POINT
    );

    return (
      verificationStatus === this.VERIFICATION_LEVEL.VERIFIED ||
      (verificationStatus === this.VERIFICATION_LEVEL.PARTIAL && !isAccurateAddressRequired) ||
      isPOBox ||
      isAmbiguousDeliveryPoint ||
      isPartialPremise
    );
  }

  #normalizeAddress(addressFields: AddressFields): Record<(typeof this.fieldsDictionary)[number]['APIKey'], string> {
    const normalized = {} as Record<(typeof this.fieldsDictionary)[number]['APIKey'], string>;

    this.fieldsDictionary.forEach(
      ({ FEKey, APIKey }) => (normalized[APIKey] = encodeURIComponent(addressFields[FEKey] ?? ''))
    );

    return normalized;
  }

  /**
   * Assembles suggestions for the user based on field level analysis from the API
   * @returns Array of objects with properties: key and suggested. An empty array will be returned if no changes are needed.
   */
  #requiredChanges(
    foundAddress: SmartyResponse,
    addressFields: AddressFields
  ): { key: keyof AddressFields; suggested: string }[] {
    const { analysis, components: suggestedValidFields, ...suggestedLineInputs } = foundAddress;
    const { changes } = analysis;
    const fieldVerification = { ...changes, ...changes.components };
    const suggestedChanges: { key: keyof AddressFields; suggested: string }[] = [];

    this.fieldsDictionary.forEach(({ FEKey, APIKey, APISuggestionKey }) => {
      // Example: V4L 1X2 or Vancouver
      // @ts-expect-error suggestedLineInputs[APIKey] causes implicit any type
      let validSuggestion = suggestedValidFields[APIKey] || suggestedLineInputs[APIKey];

      // Note: At this point only the USA needs to use postal_code_short, not postal_code.
      if (suggestedValidFields.country_iso_3 === 'USA' && APISuggestionKey) {
        // @ts-expect-error suggestedLineInputs[APIKey] causes implicit any type
        validSuggestion = suggestedValidFields[APISuggestionKey] || suggestedLineInputs[APIKey];
      }

      // Note: For US, this will be undefined every time because fieldVerification will be empty.
      const fieldChangesNeeded = fieldVerification[APIKey];

      const isSuggestionDifferentFromInput = !isNone(validSuggestion) && addressFields[FEKey] !== validSuggestion;

      const isFieldUnrecognized = fieldChangesNeeded && this.NOT_FOUND.includes(fieldChangesNeeded);

      // No suggestion needed if the api response suggestion is the same as what we sent in
      if (!isSuggestionDifferentFromInput && !isFieldUnrecognized) {
        return;
      }

      suggestedChanges.addObject({
        key: FEKey,
        suggested: isFieldUnrecognized ? '' : validSuggestion
      });
    });

    return suggestedChanges;
  }
}
